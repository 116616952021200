.designlist__page {
    .designlist__grid {
        column-gap: 24.46px;
        row-gap: 46px;

        .designitem {
            .product__grid {
                width: 287px;
                min-width: 287px;
                max-width: 287px;

                .product__block {
                    width: 287px;

                    .image__block {
                        a {
                            width: 287px;
                            // width: 100%;
                        }
                    }
                }
            }
        }
    }

    .action__block {
        button {
            width: 292px;
            font-size: 14px;
            max-height: 51px;
            min-height: 51px;
        }
    }

  
}


.Mydesign_content{
    font-size: 15px !important;
    line-height: 1.7;
    padding-bottom: 20px !important;  
}

.design_date{
    color: #222222;
    font-size: 12px !important;
    font-weight: 300;    
}

.design_product_name{
    color: #222222;
    font-weight: 600;
    font-size: 15px;
}

.design_title{
    color: #222222;
    font-size: 15px;
    font-weight: 300 !important;
}



@media (min-width: 320px) and (max-width:340px) {
    .designlist__page {
        .designlist__grid {
            column-gap: 24.46px;
            row-gap: 46px;

            .designitem {
                .product__grid {
                    width: 100%;
                    min-width: 100%;

                    .product__block {
                        width: 100%;
                        row-gap: 14px;

                        .image__block {
                            &>a {
                                width: 100%;
                                height: 304px;
                            }

                            .colorList {
                                .list {
                                    width: 90%;

                                    .pad {
                                        width: 90%;

                                        .content {
                                            .navigation-wrapper {
                                                .keen-slider {
                                                    min-width: 100% !important;
                                                    max-width: 100% !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &>.flex {
                            row-gap: 16px;

                            .product__title {
                                a:first-child {
                                    -webkit-line-clamp: 2;
                                    line-height: 1.2rem;
                                    min-height: 38.38px;
                                }
                            }
                        }

                        .price__action__block {
                            justify-content: space-between;
                            align-items: flex-end;

                            .price__block {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 340px) and (max-width:680px) {
    .designlist__page {
        .designlist__grid {
            column-gap:4%;
            .designitem {
                width: 48%;
                min-width: 48%;
                .product__grid {
                    width: 100%;
                    min-width: 100%;

                    .product__block {
                        width: 100%;
                        row-gap: 14px;

                        .image__block {
                            a {
                                width: 100%  !important;
                                // height: 304px;
                                // min-height: 100% !important;
                                // height: 100%;
                                height: 304px;
                            }
                            

                            .colorList {
                                .list {
                                    width: 90%;

                                    .pad {
                                        width: 90%;

                                        .content {
                                            .navigation-wrapper {
                                                .keen-slider {
                                                    min-width: 100% !important;
                                                    max-width: 100% !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &>.flex {
                            // row-gap: 16px;

                            .product__title {
                                a:first-child {
                                    -webkit-line-clamp: 2;
                                    line-height: 1.2rem;
                                    min-height: 38.38px;
                                }
                            }
                        }

                        .price__action__block {
                            justify-content: space-between;
                            align-items: flex-end;

                            .price__block {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 420px) and (max-width:550px) {
    .designlist__page {
        .designlist__grid {
            .designitem {
                .product__grid {
                    // min-height: 355px;
                    // height: 355px;
                    height: 100%;
                     min-height: 100%;
                    .product__block {
                        .image__block {
                            a {
                                height: 210px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 340px) and (max-width:419px) {
    .designlist__page {
        .designlist__grid {
            .designitem {
                .product__grid {
                    // min-height: 365px;
                    // height: 365px;
                    min-height: 100%;
                    height: 100%;

                    .product__block {
                        .image__block {
                            a {
                                height: 170px;
                            }
                        }
                    }

                    .price__action__block{
                        flex-direction: column;
                        justify-content: flex-start !important;
                        align-items: flex-start !important;
                        row-gap: 10px;
                    }
                }
            }
        }
    }
}